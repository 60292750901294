<template>
  <div class="homepage-wrap">
    <Carousel :data="$store.state.adList['indexCarouselNormal']" />
    <section class="main-1">
      <div class="chapter-1 clearfix-0">
        <div class="a">
          <!-- <ul class="aa clearfix-0">
            <li v-for="item of stocks" :key="item.name">
              <p class="clearfix-0">
                <span>{{ item.name }}</span>
                <span class="right" :class="[+item.gainLv < 0 ? 'minus' : 'plus']">{{ (+item.currentIndex).toFixed(2) }}</span>
              </p>
              <p>
                <span :class="[+item.gainLv < 0 ? 'minus' : 'plus']">{{ (+item.gainShu).toFixed(2) }}</span>
                <span :class="[+item.gainLv < 0 ? 'minus' : 'plus']">{{ (+item.gainLv).toFixed(2) }}%</span>
              </p>
            </li>
          </ul> -->
          <section class="bb clearfix-0">
            <div class="top-news" v-if="$store.state.adList['leftHeadNormal']">
              <h2><span>头条</span><nuxt-link class="title" target="_blank" :to="`/article/detail/${$store.state.adList['leftHeadNormal'][0].link}`" rel="noopener">{{ $store.state.adList['leftHeadNormal'][0].title }}</nuxt-link></h2>
              <p class="intro">{{ $store.state.adList['leftHeadNormal'][0].desc }}</p>
              <div class="yf-component-list_wrap clearfix">
                <div class="item left" v-for="item in $store.state.adList['leftHeadNoNormal']" :key="item.id">
                  <p class="text_wrap ellipsis">
                    <i></i><nuxt-link class="teacher" target="_blank" :to="`/article/list/${item.userid}`">{{ item.username }}</nuxt-link>：<nuxt-link target="_blank" :to="`/article/detail/${item.link}`" class="article" :title="item.title">{{ item.title }}</nuxt-link>
                  </p>
                </div>
              </div>
            </div>
            <div class="recommend-teacher" v-if="$store.state.adList['rightTeacher']">
              <h2 class="home-title">文章名家 <nuxt-link tag="a" to="/article" target="_blank" rel="noopener">更多</nuxt-link></h2>
              <ol>
                <nuxt-link v-for="item of $store.state.adList['rightTeacher'].slice(0, 3)" :to="`/article/list/${item.relationData.id}`" :key="item.id" target="_blank" rel="noopener">
                  <li class="clearfix-0">
                    <img v-lazy="isSupportWebp(item.relationData.avatar)" :alt="item.title">
                    <div class="info">
                      <div class="clearfix-0">
                        <p class="ellipsis">{{ item.relationData.userName }}</p>
                        <p class="right">
                          <span class="yf-text_red">{{ item.relationData.articleTotal }}</span> 文章
                        </p>
                      </div>
                      <p class="intro ellipsis">{{ item.relationData.sign }}</p>
                    </div>
                  </li>
                </nuxt-link>
              </ol>
            </div>
          </section>
        </div>
        <!-- <div class="right b">
          <section class="home-head clearfix-0">
            <p>快讯</p>
            <nuxt-link to="/news" target="_blank" class="right">查看更多&raquo;</nuxt-link>
          </section>
          <ul class="body">
            <li v-for="item of news" :key="item.irSid">
              <nuxt-link :to="`/news/${item.irSid}`" target="_blank">
                <span>{{ item.irLasttime.split(' ')[1].slice(0, 5) }}</span> {{ item.irUrltitle }}
              </nuxt-link>
            </li>
          </ul>
        </div> -->
        <div class="right b">
          <section class="chapter-4 clearfix-0">
          <div class="c">
            <section class="home-head clearfix-0">
              <p>最新文章</p>
              <nuxt-link to="/article/list" target="_blank" class="right">查看更多&raquo;</nuxt-link>
            </section>
            <ul>
              <li v-for="item of articles" :key="item.id">
                <p class="ellipsis">
                  <nuxt-link target="_blank" :to="`/article/detail/${item.id}`"><span v-if="item.type === 1">CJG</span>{{ item.title }}</nuxt-link>
                </p>
                <p>{{ item.userName }} {{ item.createAt.slice(5).replace('-', '/') }} </p>
              </li>
            </ul>
          </div>
        </section>
      </div>
      </div>
      <div class="chapter-2 clearfix-0">
        <div class="teacher-live" v-if="$store.state.adList['famousRoom']">
          <ul class="yf-home-live-list clearfix">
            <li class="yf-home-live-list__item left">
              <h3>热门<nuxt-link to="/room" target="_blank">查看更多&raquo;</nuxt-link></h3>
              <p>实时解析大盘 互动投资理念</p>
            </li>
            <li class="yf-home-live-list__item left" :class="teacherBedge(item.relationData.tags)" v-for="item of $store.state.adList['famousRoom'].slice(0, 8)" :key="item.id">
              <div class="clearfix">
                <div class="face left">
                  <nuxt-link :to="`/room/${item.relationData.id}`" target="_blank" rel="noopener"><img v-lazy="isSupportWebp(item.relationData.userAvatar)" :alt="item.relationData.userName"></nuxt-link>
                </div>
                <div class="content left">
                  <div class="name clearfix">
                    <nuxt-link :to="`/room/${item.relationData.id}`" target="_blank" rel="noopener"><h3 class="left ellipsis">{{ item.relationData.userName }}</h3></nuxt-link>
                    <nuxt-link :to="`/room/${item.relationData.id}`" target="_blank" rel="noopener"><button type="button" class="right" :class="{ 'yf-status-open': item.relationData.status === 11, 'yf-status-pause': item.relationData.status === 12, 'yf-status-close': item.relationData.status === 21 }">{{ item.relationData.status | famousRoomStatus }}</button></nuxt-link>
                  </div>
                  <p class="intro ellipsis">{{ item.relationData.introduce }}</p>
                  <p class="yf-keyword">
                    <span v-for="(w, index) of item.relationData.label.split(',')" :key="index">{{ w }}</span>
                  </p>
                </div>
              </div>
              <p class="title ellipsis"><span>主题：</span><nuxt-link :to="`/room/${item.relationData.id}`" target="_blank" rel="noopener">{{ item.relationData.topicName }}</nuxt-link></p>
            </li>
          </ul>
        </div>
        <div class="popular-rank right" v-if="rankData">
          <section class="home-head clearfix-0">
            <p>日均人气排行</p>
          </section>
          <ol class="yf-rank-list">
            <li class="clearfix yf-rank-list__item" v-for="item of rankData.slice(0, 9)" :key="item.roomId">
              <i class="left rank" :class="`rank-${item.rank}`">{{ item.rank }}</i>
              <h4 class="left ellipsis"><nuxt-link :to="`/room/${item.roomId}`" target="_blank">{{ item.teacherName }}</nuxt-link></h4>
              <span class="left ticket ellipsis">{{ item.num }}</span>
              <svg class="arrow-up" width="20" height="20" v-if="item.rank <= item.preRank">
                <circle cx="10" cy="10" r="9" stroke="#ff7a7a" stroke-width="1" fill="#fff" />
                <path d="M 5 10 l 5 -5" stroke="#ff7a7a" stroke-width="1" fill="none" />
                <path d="M 10 5 l 5 5" stroke="#ff7a7a" stroke-width="1" fill="none" />
                <path d="M 10 5 l 0 10" stroke="#ff7a7a" stroke-width="1" fill="none" />
              </svg>
              <svg class="arrow-down" width="20" height="20" v-else>
                <circle cx="10" cy="10" r="9" stroke="#63c9ad" stroke-width="1" fill="#fff" />
                <path d="M 5 10 l 5 5" stroke="#63c9ad" stroke-width="1" fill="none" />
                <path d="M 10 15 l 5 -5" stroke="#63c9ad" stroke-width="1" fill="none" />
                <path d="M 10 5 l 0 10" stroke="#63c9ad" stroke-width="1" fill="none" />
              </svg>
            </li>
          </ol>
        </div>
      </div>
      <section class="chapter-3 clearfix-0">
        <ul class="recommend-product">
          <li class="clearfix" v-for="item in $store.state.adList['indexGoodsNormal']" :key="item.id">
            <div class="left avatar">
              <a :href="item.link" target="_blank" rel="noopener"><img v-lazy="isSupportWebp(item.image)" :alt="item.title"></a>
            </div>
            <a :href="item.link" target="_blank" rel="noopener"><div class="left content" v-html="item.desc"></div></a>
          </li>
        </ul>
        <!-- <div class="right hot-training" v-if="$store.state.adList['indexTraining']">
          <section class="home-head clearfix-0">
            <p>热门特训班</p>
            <nuxt-link to="/vip" target="_blank" class="right">查看更多&raquo;</nuxt-link>
          </section>
          <ol>
            <li class="clearfix" v-for="item of $store.state.adList['indexTraining'].slice(0, 3)" :key="item.id">
              <div class="left hot-training-face">
                <nuxt-link :to="`/vip/room/${item.relationData.id}`" target="_blank" rel="noopener"><img v-lazy="isSupportWebp(item.relationData.userAvatar)" :alt="item.relationData.userName"></nuxt-link>
              </div>
              <div class="left hot-training-content">
                <h4 class="ellipsis"><nuxt-link :to="`/vip/room/${item.relationData.id}`" target="_blank" rel="noopener">{{ item.relationData.title }}</nuxt-link></h4>
                <p class="teacher">主讲人：{{ item.relationData.userName }}</p>
                <p class="yf-keyword">
                  <span v-for="(w, i) in JSON.parse(item.relationData.keywords)" :key="i">{{ w }}</span>
                </p>
              </div>
            </li>
          </ol>
        </div> -->
      </section>
      <section class="chapter-4 clearfix-0">
        <div class="a">
          <section class="home-head clearfix-0">
            <p>最新资讯</p>
            <nuxt-link to="/article" target="_blank" class="right">查看更多&raquo;</nuxt-link>
          </section>
          <ul>
            <li v-for="item of infos" :key="item.irSid">
              <p class="ellipsis">
                <nuxt-link :to="`/article/detail/${item.irSid}?irSid=1`" target="_blank"><span class="inline"></span>{{ item.irUrltitle }}</nuxt-link>
              </p>
              <p>
                {{ item.irLasttime }}
              </p>
            </li>
          </ul>
        </div>
        <div class="b">
          <section class="home-head clearfix-0">
            <p>最新问答</p>
            <nuxt-link to="/question" target="_blank" class="right">查看更多&raquo;</nuxt-link>
          </section>
          <ul>
            <li class="clearfix-0" v-for="item of questions" :key="item.id">
              <p>问</p>
              <div>
                <p>{{ item.answerUserName }}： {{ item.createdAt }} </p>
                <nuxt-link :to="`/question/detail/${item.id}`" class="ellipsis" target="_blank">{{ item.body }}</nuxt-link>
              </div>
            </li>
          </ul>
        </div>
        <div class="c">
          <section class="home-head clearfix-0">
            <p>最新文章</p>
            <nuxt-link to="/article/list" target="_blank" class="right">查看更多&raquo;</nuxt-link>
          </section>
          <ul>
            <li v-for="item of articles" :key="item.id">
              <p class="ellipsis">
                <nuxt-link target="_blank" :to="`/article/detail/${item.id}`"><span v-if="item.type === 1">CJG</span>{{ item.title }}</nuxt-link>
              </p>
              <p>{{ item.userName }} {{ item.createAt.slice(5).replace('-', '/') }} </p>
            </li>
          </ul>
        </div>
      </section>
      <section class="chapter-5">
        <section class="home-head clearfix-0">
          <p>推荐书籍</p>
          <ul class="right">
            <li :class="{ active: index === mark }" v-for="(v, index) of bookmenu" :key="index" @click="updateBooks(index, v)">{{ v.className }}</li>
          </ul>
        </section>
        <ul class="book-list clearfix" :key="mark">
          <li class="left" v-for="item of books" :key="item.id">
            <p class="book"><nuxt-link :to="`/library/${item.id}`" target="_blank" rel="noopener"><img v-lazy="item.image" alt=""></nuxt-link></p>
            <p class="bookname ellipsis"><nuxt-link :to="`/library/${item.id}`" target="_blank" rel="noopener">{{ item.bookName }}</nuxt-link></p>
            <p class="author ellipsis">{{ item.author }}</p>
          </li>
        </ul>
      </section>
      <section class="chapter-6" v-if="friendLinks.length">
        <section class="home-head clearfix-0">
          <p>友情链接</p>
        </section>
        <div class="links">
          <a :href="item.url" target="_blank" v-for="item of friendLinks" :key="item.id">{{ item.title }}</a>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import { famousRoomStatus, teacherBedge } from '~/utils/Filters'
import Webp from '@/mixins/Webp'
import Carousel from '@/components/Carousel'
import { setInterval, clearInterval } from 'timers';

export default {
  mixins: [Webp],
  data () {
    return {
      stockTimer: null,
      stocks: [],
      mark: 0,
      bookmenu: [],
      books: [],
      friendLinks: [],
      rankData: [],
      infos: [],
      questions: [],
      articles: [],
      news:[]
    }
  },
  mounted () {
    // 获取股票指数，每60秒一次
    // 参考https://www.cnblogs.com/rockchip/p/3182525.html
    // this.getStocks()
    // let time = (new Date()).getHours()
    // if ((time >= 9 && time < 12) || (time >= 13 && time < 15)) {
    //   this.stockTimer = setInterval(() => {
    //     this.getStocks()
    //   }, 1000 * 60)
    // }
    
    // 快讯每五分钟更新一次
    this.getNews()
    this.timer = setInterval(() => {
      this.getNews()
    }, 1000 * 300)
    
    this.$axios._get('/Teacher/Rank', { type: 3 }).then(res => {
      this.rankData = res
    }).catch(err => {
      console.log('rank_err: ' + err)
    })
    this.$axios._get('/Common/Flink').then(res => {
      this.friendLinks = res
    })
    this.$store.dispatch('getAd', ['indexCarouselNormal', 'leftHeadNormal', 'leftHeadNoNormal', 'rightTeacher', 'famousRoom', 'indexGoodsNormal', 'indexTraining'])
    this.$axios._get('/Information/List').then(res => {
      this.infos = res.slice(0, 5)
    })
    this.$axios._get('/Ask/List', { num: 5 }).then(res => {
      this.questions = res.list
    })
    this.$axios._get('/Article/List', { num: 5, service: 1 }).then(res => {
      this.articles = res.list
    })
    this.$axios._get('/Books/Classification').then(res => {
      let arr = [
        { id: -1, className: '最新图书' },
        { id: -2, className: '最热图书' },
      ]
      this.bookmenu = arr.concat(res)
      this.updateBooks(0, this.bookmenu[0])
    })
  },
  beforeDestroy () {
    this.stockTimer && clearInterval(this.stockTimer)
  },
  methods: {
    getNews () {
      // 获取快讯
      this.$axios._get('/Information/OtherList', { type: 6, page: 1, num: 4 }).then(res => {
        this.news = res.list
      })
    },
    getStocks () {
      this.$axios._get('/Information/Shareindex').then(res => {
        this.stocks = res
      })
    },
    updateBooks (i, item) {
      this.mark = i
      let params = {
        num: 7
      }
      if (item.id > 0) {
        params.bookclassId = item.id
      } else {
        params.order = Math.abs(item.id)
      }
      this.$axios._get('/Books/List', params).then(res => {
        this.books = res.list
      })
    },
    teacherBedge
  },
  filters: {
    famousRoomStatus
  },
  components: {
    Carousel
  }
}
</script>

<style lang="less">
// 通用(没加scoped、得用homepage-wrap嵌套，防止影响其他页面元素)
.homepage-wrap {
  img { display: block; }
  .home-head {
    border-bottom: 1px solid #eaeaea; padding: 0 16px; line-height: 50px;
    p {
      font-weight: bold;
    }
    a {
      font-size: 12px; color: #999999;
    }
  }
}
// 常规
.homepage-wrap {
  padding-bottom: 30px;
  .chapter-1 {
    margin-top: 20px;
    .a {
      .aa {
        border: 1px solid #eaeaea;
        li {
          width: 224px; padding: 17px 16px 14px;
          &:not(:last-child) {
            border-right: 1px solid #eaeaea;
          }
          p:first-child {
            span:last-child {
              font-size: 18px; font-weight: bold;
            }
          }
          P:last-child {
            width: 191px; text-align: right; font-size: 14px;
          }
          .plus {
            color: #f52f3e;
          }
          .minus {
            color: #6ac18d;
          }
        }
      }
      .bb {
        margin-top: 8px;
        .top-news {
          width: 598px; border: 1px solid #eaeaea; border-right: none; padding: 28px;
          .title {
            font-size: 24px; color: #333;
          }
          .intro {
            font-size: 14px; color: #666; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; margin-top: 20px; height: 38px;
          }
        }
        .yf-component-list_wrap {
          .item {
            margin-top: 12px; width: 252px;
            &:nth-child(odd) { margin-right: 36px; }
            p a {
              font-size: 15px;
            }
            i {
              vertical-align: 8%; border-color: #e6394d;
            }
          }
        }
        .recommend-teacher {
          width: 300px; border: 1px solid #eaeaea; padding: 19px 20px;
          .home-title {
            font-size: 16px; font-weight: bold;
            a {
              font-size: 12px; vertical-align: baseline; color: #999999;
            }
          }
          ol {
            margin-top: 6px; height: 234px;
            > a:not(:last-child) {
              li {
                border-bottom: 1px solid #eaeaea;
              }
            }
            li {
              width: 260px; padding: 20px 0;
              img {
                width: 50px; height: 50px; border-radius: 50%; margin: 0 15px 0 0;
              }
              .info {
                width: 195px;
                div {
                  margin-top: 4px;
                  p {
                    &:first-child {
                      width: 100px;
                    }
                    &:last-child {
                      font-size: 14px; margin-top: 2px; color: #999999;
                    }
                  }
                }
                .intro {
                  font-size: 14px; color: #666; margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
    .b {
      width: 293px; border: 1px solid #eaeaea;
      .body {
        padding: 13px 20px;
        li {
          font-size: 14px; padding: 20px 0; border-bottom: 1px dashed #eaeaea;
          a {
            display: block; height: 40px; overflow: hidden;
            span {
              background-color: #666; font-size: 12px; color: #ffffff; line-height: 20px; border-radius: 10px; padding: 0 6px;
            }
          }
        }
      }
    }
  }
  .chapter-2 {
    margin-top: 8px;
    .teacher-live {
      width: 900px;
      .yf-home-live-list {
        .yf-home-live-list__item {
          width: 300px; padding: 18px 15px;
          &:first-child {
            height: 147px; background-image: url(~assets/famous-bg.jpg); background-size: 100% 100%; padding: 0; border: 0; padding: 30px 0 0 24px;
            h3 {
              color: #ffffff; font-size: 24px;
              a {
                color: #ffffff; font-size: 12px; display: inline-block; margin-left: 6px;
              }
            }
            p {
              color: #ffcccc; border: 1px dotted #ffcccc; width: 210px; text-align: center; margin-top: 14px;; line-height: 30px;
            }
          }
          &:not(:nth-child(3n+1)) { margin-left: -1px; }
        }
      }
    }
    .popular-rank {
      width: 293px;
      .home-head {
        border: 1px solid #eaeaea; line-height: 49px;
      }
      .yf-rank-list {
        border-top: 0; padding: 0 15px;
        .yf-rank-list__item {
          padding: 8px 0;
          h4 {
            font-size: 14px; line-height: 26px; width: 130px;
          }
        }
      }
    }
  }
  .chapter-3 {
    margin-top: 8px;
    .recommend-product {
      width: 898px;
      li {
        border: 1px solid #eaeaea; padding: 19px 20px; transition: background-color .3s;
        &:not(:first-child) { margin-top: -1px; }
        .avatar {
          width: 100px;
          img {
            width: inherit; height: 100px; border-radius: 50%;
          }
        }
        .content {
          margin-left: 30px; width: 710px;
          h3 {
            font-size: 22px; line-height: 44px; color: #333;
          }
          .intro {
            font-size: 14px; color: #999; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
          }
          .tip {
            color: #333; font-size: 16px; margin-top: 21px; margin-left: -8px; line-height: 1;
            span {
              color: #f00;
            }
          }
        }
      }
    }
    .hot-training {
      margin-left: 9px; width: 293px; border: 1px solid #eaeaea;
      ol {
        margin-top: 3px;
        li {
          padding: 21px 5px; position: relative;
          &:not(:last-child) {
            border-bottom: 1px solid #eaeaea;
          }
          .hot-training-face img {
            width: 50px; height: 50px; border-radius: 50%;
          }
          .hot-training-content {
            margin-left: 10px; line-height: 1;
            h4 {
              font-size: 16px; color: #222; font-weight: 600; width: 195px; height: 16px;
            }
            p {
              font-size: 12px; margin-top: 12px;
              &.teacher {
                color: #333;
              }
            }
          }
        }
      }
    }
  }
  .chapter-4 {
    .a,.b,.c {
      ul {
        padding: 0 20px;
      }
    }
    .a {
      width: 448px; margin-right: 10px; padding-bottom: 20px;
      ul {
        li {
          margin-top: 20px;
          p:first-child {
            span {
              width: 6px; height: 6px; background-color: #ccc; border-radius: 50%; vertical-align: middle; margin-right: 6px;
            }
          }
          p:last-child {
            font-size: 14px; color: #999999; margin-left: 12px; margin-top: 6px;
          }
        }
      }
    }
    .b {
      width: 440px; margin-right: 10px;
      ul {
        padding-bottom: 35px;
        li {
          margin-top: 21px;
          > p {
            background-color: #e43a3d; width: 28px; height: 28px; border-radius: 50%; color: #ffffff; text-align: center; line-height: 28px; margin-right: 10px; margin-top: 8px;
          }
          div {
            width: 360px;
            p:first-child {
              color: #999999; font-size: 14px;
            }
            a {
              margin-top: 2px; display: block;
            }
          }
        }
      }
    }
    .c {
      width: 292px;
      ul {
        padding-bottom: 40px;
        li {
          margin-top: 18px;
          p:first-child {
            a {
              line-height: 20px;
            }
            span {
              font-size: 14px; background-color: #f09800; color: #ffffff; display: inline-block; width: 40px; border-radius: 10px; text-align: center; margin-right: 6px;
            }
          }
          p:last-child {
            font-size: 14px; color: #999999; margin-top: 4px;
          }
        }
      }
    }
  }
  .chapter-5 {
    margin-top: 8px; border: 1px solid #eaeaea;
    .home-head {
      line-height: 58px;
      ul {
        overflow: hidden;
        li {
          font-size: 14px; color: #666; float: left; margin-left: 10px; cursor: pointer;
          &.active { font-weight: 600; color: #000; }
        }
      }
    }
    .book-list {
      li {
        padding: 30px 18px 16px; transition: background-color .3s;
        &:hover { background-color: #f5f5f5; }
        &:first-child { padding-left: 37px; }
        .book img {
          width: 130px; height: 170px;
        }
        .bookname,.author {
          color: #333; font-size: 12px; margin-top: 10px; width: 130px;
        }
      }
    }
  }
  .chapter-6 {
    margin-top: 8px;
    .home-head {
      border: 1px solid #eaeaea; border-bottom: 0;
    }
    .links {
      border: 1px solid #eaeaea; padding: 19px 16px; font-size: 14px;
      a {
        display: inline-block; color: #666;
        &:hover { color: #222; }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
