<template>
<section
  class="component__carousel"
  @mouseenter.stop="pauseTimer"
  @mouseleave.stop="startTimer"
  :style="{ height: height }">
  <transition-group name="carousel-fade" mode="out-in">
    <div
      class="carousel-item"
      v-for="(item, index) of data"
      v-show="activeIndex === index"
      :key="item.id"
      :style="{ 'background-color': item.bgcolor }">
      <nuxt-link :to="item.link" target="_blank" rel="noopener">
        <img :src="isSupportWebp(item.image, true)" :style="{ height: height }" :alt="item.title">
      </nuxt-link>
    </div>
  </transition-group>
  <slot></slot>
  <!-- TODO:增加左右箭头 -->
  <!-- <transition name="carousel-arrow-left">
    <div class="carousel-arrow-left" v-show="showArrow">
      <i class="el-icon-arrow-left"></i>
    </div>
  </transition>
  <transition name="carousel-arrow-right">
    <div class="carousel-arrow-right" v-show="showArrow">
      <i class="el-icon-arrow-right"></i>
    </div>
  </transition> -->
  <div class="indicators" v-if="data">
    <div class="indicator-item" v-for="item of data.length" :key="item" @click="activeIndex = item - 1">
      <button class="indicator-item-btn" type="button" :class="{ active: activeIndex === item - 1 }"></button>
    </div>
  </div>
</section>
</template>

<script>
import Webp from '@/mixins/Webp'

export default {
  mixins: [Webp],
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    height: {
      type: String,
      default: '250px'
    }
  },
  data () {
    return {
      activeIndex: 0,
      timer: null,
      showArrow: false
    }
  },
  mounted () {
    this.startTimer()
  },
  methods: {
    startTimer () {
      this.timer = setInterval(() => {
        if (this.activeIndex < this.data.length - 1) {
          this.activeIndex++
        } else {
          this.activeIndex = 0
        }
      }, 3500)
    },
    pauseTimer () {
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="less">
.component__carousel {
  position: relative;
  .carousel-item {
    position: absolute; left: 0; top: 0; z-index: 100; width: 100%; text-align: center;
    img {
      width: 900px; margin-left: 50%; transform: translateX(-50%);
    }
  }
  .carousel-arrow-left,
  .carousel-arrow-right {
    position: absolute; top: 50%; transform: translateY(-50%); z-index: 100; width: 36px; height: 36px; background-color: rgba(0, 0, 0, .24); border-radius: 18px; text-align: center; cursor: pointer;
    i {
      color: #ffffff; margin-top: 10px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, .64);
    }
  }
  .carousel-arrow-left {
    left: 30px;
  }
  .carousel-arrow-right {
    right: 30px;
  }
  .indicators {
    position: absolute; left: 50%; bottom: 0; z-index: 1000; overflow: hidden; transform: translateX(-50%);
    .indicator-item {
      width: 40px; height: 30px; float: left; text-align: center; cursor: pointer;
      .indicator-item-btn {
        width: 30px; height: 2px; background-color: #fff; opacity: .45; transition: all .3s;
        &.active {
          opacity: 1;
        }
      }
      &:hover {
        .indicator-item-btn {
          opacity: .75;
        }
      }
    }
  }
}
</style>
